// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analityka-index-js": () => import("./../../../src/pages/analityka/index.js" /* webpackChunkName: "component---src-pages-analityka-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kampanie-sm-index-js": () => import("./../../../src/pages/kampanie-sm/index.js" /* webpackChunkName: "component---src-pages-kampanie-sm-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-reklamy-google-index-js": () => import("./../../../src/pages/reklamy-google/index.js" /* webpackChunkName: "component---src-pages-reklamy-google-index-js" */),
  "component---src-pages-seo-index-js": () => import("./../../../src/pages/seo/index.js" /* webpackChunkName: "component---src-pages-seo-index-js" */)
}

